<template>
  <v-container id="crud" fluid tag="section">
    <v-card>
      <v-toolbar flat color="white">
        <v-toolbar-title>Administración de {{ title }}</v-toolbar-title>
        <v-spacer></v-spacer>

        <v-switch
          v-model="switchValue"
          color="primary"
          hide-details
          label="Búsqueda por lote de estampillas"
          title="Haga click para visualzar los datos por serie o lote de estampillas"
          @click="switchGrid()"
        ></v-switch>
      </v-toolbar>

      <v-data-table
        :headers="showHeaders"
        :key="keyTable"
        :items="filteredData"
        :search="search"
        sort-by="created_at"
        sort-desc
        class="elevation-1"
      >
        <template v-slot:[`header.codigo`]="{ header }">
          <filter-component
            v-bind:header="{ header }"
            v-bind:filters="filters"
          ></filter-component>
        </template>
        <template v-slot:[`header.item`]="{ header }">
          <filter-component
            v-bind:header="{ header }"
            v-bind:filters="filters"
          ></filter-component>
        </template>
        <template v-slot:[`header.descripcion`]="{ header }">
          <filter-component
            v-bind:header="{ header }"
            v-bind:filters="filters"
          ></filter-component>
        </template>
        <template v-slot:[`header.numero_desde`]="{ header }">
          <filter-component
            v-bind:header="{ header }"
            v-bind:filters="filters"
          ></filter-component>
        </template>
        <template v-slot:[`header.numero_hasta`]="{ header }">
          <filter-component
            v-bind:header="{ header }"
            v-bind:filters="filters"
          ></filter-component>
        </template>
        <template v-slot:[`header.cantidad`]="{ header }">
          <filter-component
            v-bind:header="{ header }"
            v-bind:filters="filters"
          ></filter-component>
        </template>
        <template v-slot:[`header.numero`]="{ header }">
          <filter-component
            v-bind:header="{ header }"
            v-bind:filters="filters"
          ></filter-component>
        </template>
        <template v-slot:[`header.almacen`]="{ header }">
          <filter-component
            v-bind:header="{ header }"
            v-bind:filters="filters"
          ></filter-component>
        </template>
        <template v-slot:[`item.activo`]="{ item }">
          <div :class="$getCss(item.activo, 'activo')" dark>
            {{ $getValue(item.activo, "activo") }}
          </div>
        </template>
        <template v-slot:[`item.created_at`]="{ item }">
          {{ item.created_at }}
        </template>
        <template v-slot:[`item.updated_at`]="{ item }">
          {{ item.updated_at }}
        </template>
        <template v-slot:[`item.valor`]="{ item }">
          {{ "$" + Number(item.valor).toLocaleString("es-AR") }}
        </template>

        <template v-slot:top>
          <v-toolbar flat color="white" class="pr-4">
            <v-row>
              <v-col sm="6" class="pt-20">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  :label="'Busqueda de ' + title"
                  single-line
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col sm="2"></v-col>
              <v-col sm="3" class="">
                <v-autocomplete
                  v-model="selectedHeaders"
                  :items="headers"
                  label="Columnas Visibles"
                  multiple
                  return-object
                >
                  <template v-slot:selection="{ item, index }">
                    <v-chip v-if="index < 2">
                      <span>{{ item.text }}</span>
                    </v-chip>
                    <span v-if="index === 2" class="grey--text caption"
                      >(otras {{ selectedHeaders.length - 2 }}+)</span
                    >
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col sm="1">
                <v-dialog v-model="dialog" max-width="20%" persistent>
                  <!--
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-if="$can(['estampillas_ingreso_stock'])"
                      :color="$cv('principal')"
                      dark
                      class="mx-2"
                      fab
                      v-bind="attrs"
                      v-on="on"
                      ><v-icon blue>mdi-plus</v-icon></v-btn
                    >
                  </template>
                -->
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-if="$can(['ingreso_stock_estampillas'])"
                      :color="$cv('principal')"
                      dark
                      class="mx-2"
                      fab
                      v-bind="attrs"
                      v-on="on"
                      ><v-icon blue>mdi-plus</v-icon></v-btn
                    >
                  </template>
                  <v-card>
                    <v-form ref="form" v-model="valid" lazy-validation>
                      <v-toolbar dark :color="$cv('principal')">
                        <!--
                        <v-btn icon dark @click="dialog = false">
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                        -->
                        <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <!--
                        <v-toolbar-items>
                          <v-btn
                            dark
                            text
                            @click="$save()"
                            v-if="!vista"
                            :disabled="!valid"
                            >Guardar</v-btn
                          >
                        </v-toolbar-items>
                        -->
                      </v-toolbar>

                      <v-card-text>
                        <v-container>
                          <v-row>
                            <v-col cols="12" md="12" sm="12">
                              <v-text-field
                                v-model="editedItem.codigo"
                                label="Codigo de desbloqueo"
                                filled
                                dense
                                class="centered-input"
                                hint="Ingrese el número de remito de compra de estampillas"
                                :disabled="vista"
                                :rules="[
                                  $rulesRequerido,
                                  $rulesAlfaNum,
                                  $rulesMax500,
                                ]"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col align="right" cols="12" md="12" sm="12">
                              <v-btn color="red" @click="dialog = false"
                                >Cancelar</v-btn
                              >
                              <v-btn
                                :disabled="!valid"
                                color="success"
                                class="mr-4"
                                @click="save()"
                              >
                                Ingresar
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>
                    </v-form>
                  </v-card>
                </v-dialog>
                <v-dialog v-model="dialogHistorial" max-width="70%" persistent>
                  <v-card>
                    <v-form ref="form" v-model="valid" lazy-validation>
                      <v-toolbar dark color="primary">
                        <v-btn icon dark @click="dialogHistorial = false">
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <v-toolbar-title>{{ titleHistorial }}</v-toolbar-title>
                        <v-spacer></v-spacer>
                      </v-toolbar>
                      <v-card-text style="overflow-y: scroll; max-height: 72vh">
                        <v-data-table
                          :headers="headersHistorial"
                          :items="historial"
                          sort-by="created_at"
                        >
                        </v-data-table>
                      </v-card-text>
                    </v-form>
                  </v-card>
                </v-dialog>
              </v-col>
            </v-row>
          </v-toolbar>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-row class="">
            <v-col cols="6" md="6" sm="6" style="padding: 6px 0px 0px 0px">
              <v-menu
                bottom
                origin="center center"
                transition="scale-transition"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="transparent elevation-0"
                    dark
                    small
                    v-bind="attrs"
                    v-on="on"
                    min-width="30px"
                  >
                    <v-icon
                      large
                      color="blue-grey lighten-3"
                      style="font-size: 30px"
                    >
                      mdi-dialpad
                    </v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <!--
                  <v-list-item
                    v-if="
                      switchValue && $can(['estampillas_inhabilitacion_stock'])
                    "
                    @click="deleteItem(item.id, item.descripcion)"
                  >
                  -->
                  <v-list-item
                    v-if="
                      switchValue && $can(['inhabilitar_stock_estampillas'])
                    "
                    @click="deleteItem(item.id, item.descripcion)"
                  >
                    <v-list-item-icon class="mr-0">
                      <v-icon
                        small
                        class="mr-2"
                        :color="$cv('btnEliminar')"
                        title="Eliminar"
                      >
                        mdi-delete
                      </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>Inhabilitar Lote</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    v-else-if="$can(['inhabilitar_stock_estampillas'])"
                    @click="deleteItem(item.id, item.numero)"
                  >
                    <v-list-item-icon class="mr-0">
                      <v-icon
                        small
                        class="mr-2"
                        :color="$cv('btnEliminar')"
                        title="Eliminar"
                      >
                        mdi-delete
                      </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title
                        >Inhabilitar Estampilla</v-list-item-title
                      >
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    v-if="!switchValue && $can(['historial_stock_estampillas'])"
                    @click="getHistory(item)"
                  >
                    <v-list-item-icon class="mr-0">
                      <v-icon
                        small
                        class="mr-2"
                        :color="$cv('btnActivo')"
                        title="Ver historial"
                      >
                        mdi-history
                      </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>Ver historial</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
          </v-row>
        </template>
      </v-data-table>
    </v-card>

    <v-snackbar
      v-model="snackbar"
      :bottom="true"
      :color="color"
      :timeout="timeout"
    >
      <div v-html="text"></div>

      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbar = false">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
    <vue-confirm-dialog></vue-confirm-dialog>
  </v-container>
</template>

<script>
function title() {
  return "Stock de Estampillas";
}

import itemsSAP from "@/assets/responseEstampillasSAP.json";
export default {
  data: (vm) => ({
    valid: true,
    title: title(),
    routeLote: "stockLotes",
    routeLoteFormulario: "stockLotesFormularios",
    routeLoteRegistrar: "asignarLote",
    routeLotesEntidad: "stockByEntidadByProducto",
    routeSerieEntidad: "stockSerieByEntidadByProducto",
    titlePerms: "estampillas_informe_stock",
    dialog: false,
    routeHistorial: "historialByProducto",
    dialogHistorial: false,
    titleHistorial: "",
    snackbar: false,
    visible: true,
    text: "Registro Insertado",
    color: "success",
    timeout: 4000,
    search: "",
    vista: false,
    headersLote: [
      { text: "Acciones", value: "actions", sortable: false },
      { text: "Codigo", value: "codigo" },
      { text: "Item", value: "item" },
      { text: "Descripcion", value: "descripcion" },
      { text: "Desde", value: "numero_desde" },
      { text: "Hasta", value: "numero_hasta" },
      { text: "Cantidad", value: "cantidad" },
      { text: "Total", value: "total" },
    ],
    headers: [
      { text: "Acciones", value: "actions", sortable: false },
      { text: "Numero", value: "numero" },
      { text: "Item", value: "item" },
      { text: "Valor", value: "valor" },
      { text: "Almacen", value: "almacen" },
      { text: "Descripcion", value: "descripcion" },
    ],
    headersHistorial: [
      { text: "Almacen", value: "almacen" },
      { text: "Fecha", value: "created_at" },
    ],
    historial: [],
    desserts: [],
    editedIndex: -1,
    editedItem: {},
    keyTable: 0,
    filters: {
      codigo: "",
      item: "",
      descripcion: "",
      desde: "",
      hasta: "",
      cantidad: "",
      almacen: "",
    },
    filterKey: [],
    selectedHeaders: [],
    idProducto: 3,
    switchValue: false,
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Ingreso de Stock"
        : "Editar " + this.title;
    },
    filteredData() {
      return this.$filteredData().data !== undefined
        ? this.$filteredData().data
        : this.$filteredData();
    },
    showHeaders() {
      if (!this.switchValue) {
        //this.keyTable = +1;
        return this.headers.filter((s) => this.selectedHeaders.includes(s));
      } else {
        //this.keyTable = +1;
        return this.headersLote.filter((s) => this.selectedHeaders.includes(s));
      }
    },
  },

  watch: {
    dialog(val) {
      val || this.$close();
    },
  },

  created() {
    this.getData();
    this.selectedHeaders = !this.switchValue ? this.headers : this.headersLote;
  },

  methods: {
    async getHistory(item) {
      let solicitud = item.numero;
      let id = item.id;
      Swal.alertGetInfo("Buscando información");
      await this.$axiosApi
        .getById(this.routeHistorial, id)
        .then((r) => {
          Swal.close();
          this.titleHistorial = "Historial de Estampilla Número " + solicitud;
          console.log(r.data.data);
          this.historial = r.data.data;
          this.dialogHistorial = true;
        })
        .catch(function (error) {
          Swal.close();
          console.log(error);
          Swal.alertError(
            "Error al consultar informacion ",
            "Ha ocurrido un error al buscar datos de " + this.stockData.entidad
          );
        });
    },
    /*getHistory(item) {
      this.$alertDev();
    },*/
    switchGrid() {
      //console.log(this.switchValue);
      if (this.switchValue) {
        this.getData();
        this.selectedHeaders = this.headersLote;
        this.title = "Stock de Lote de Estampillas";
        this.keyTable = +1;
      } else {
        this.getData();
        this.selectedHeaders = this.headers;
        this.title = "Stock de Estampillas";
        this.keyTable = +1;
      }
    },
    async deleteItem(id, nombre) {
      {
        let title = this.switchValue
          ? "Se inhabilitarán las estampillas " +
            nombre +
            " y no podrán volver a ser utilizadas. ¿Está seguro?"
          : "Se inhabilitará la estampilla <b>Número " +
            nombre +
            "</b> y no podrá volver a ser utilizada. ¿Está seguro?";
        let titleOk = this.switchValue
          ? "Las estampillas fueron inhabilitadas existosamente"
          : "La estampilla fue inhabilitada existosamente";
        Swal.fire({
          title: "Stock de Estampillas",
          /*html: "Se ingresarán las estampillas a su stock de almacén de ventas. ¿Está seguro?",*/
          html: title,
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Aceptar",
          cancelButtonText: "Cancelar",
          showLoaderOnConfirm: true,
          allowOutsideClick: () => !Swal.isLoading(),
        }).then((result) => {
          if (result.isConfirmed) {
            Swal.fire({
              title: "Aguarde un instante!",
              icon: "info",
              html: "Eliminando Datos",
              allowOutsideClick: false,
              showConfirmButton: false,
              onBeforeOpen: () => {
                Swal.showLoading();
              },
            });
            let url = this.switchValue
              ? this.routeLote
              : this.routeLoteFormulario;

            this.$axiosApi.delete(url, id).then(
              (response) => {
                if (response.status == 200) {
                  Swal.close();
                  Swal.fire({
                    icon: "success",
                    title: "Inhabilitación de Stock de Estampillas",
                    confirmButtonText: "Aceptar",
                    html: titleOk,
                    allowOutsideClick: false,
                  }).then((r) => {
                    if (r.isConfirmed) {
                      this.getData();
                    }
                  });
                } else {
                  Swal.close();
                  Swal.fire({
                    icon: "warning",
                    title: "Error al registrar los datos de stock",
                    html:
                      "<div align='left'>" + response.data.message + "</div>",
                  });
                }
              },
              (error) => {
                Swal.close();
                Swal.fire({
                  icon: "error",
                  title: "Error",
                  text: "Error al registrar los datos de stock" + error,
                  footer: '<a href="">Contacte con el administrador</a>',
                });
              }
            );
          } else {
            console.log("cancelar");
          }
        });
      }
    },
    async getData() {
      Swal.alertGetInfo("Buscando información");
      await this.$sleep(1000);
      let entidad = this.$store.state.user.entidades[0];
      let id = entidad + "/" + this.idProducto;
      let route =
        this.switchValue == true
          ? this.routeLotesEntidad
          : this.routeSerieEntidad;

      await this.$axiosApi
        .getById(route, id)
        .then((r) => {
          Swal.close();
          this.desserts = r.data.data;
        })
        .catch(function (error) {
          Swal.close();
          console.log(error);
          Swal.alertError(
            "Error al consultar informacion ",
            "Ha ocurrido un error al buscar datos de " + this.stockData.entidad
          );
        });
    },
    async save() {
      // aca deberia ir la consulta al ws de SAP
      let resSAP = itemsSAP.data;

      Swal.fire({
        title: "Ingreso de Stock de Estampillas",
        /*html: "Se ingresarán las estampillas a su stock de almacén de ventas. ¿Está seguro?",*/
        html:
          "Se ingresarán " +
          resSAP.cantidad +
          " " +
          resSAP.descripcion +
          "(item " +
          resSAP.item +
          ") a su stock de almacén de ventas. ¿Está seguro?",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cancelar",
        showLoaderOnConfirm: true,
        allowOutsideClick: () => !Swal.isLoading(),
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            title: "Aguarde un instante!",
            icon: "info",
            html: "Registrando Datos ",
            allowOutsideClick: false,
            showConfirmButton: false,
            onBeforeOpen: () => {
              Swal.showLoading();
            },
          });
          //this.saveSlepp();
          let codigo_estampilla = {
            id: this.idProducto,
            detalle: "estampillas",
          };

          let formData = {
            entidad_id: this.$store.state.user.entidades[0],
            codigo: this.editedItem.codigo,
            tipo_formulario_id: codigo_estampilla,
            item: resSAP.item,
            descripcion: resSAP.descripcion,
            inicio: resSAP.desde,
            fin: resSAP.hasta,
            cantidad: resSAP.cantidad,
            total: resSAP.total,
          };

          this.$axiosApi.post(this.routeLoteRegistrar, formData).then(
            (response) => {
              if (response.status == 201) {
                Swal.close();
                Swal.fire({
                  icon: "success",
                  title: "Ingreso de Stock de Estampillas",
                  confirmButtonText: "Aceptar",
                  html: "Las estampillas fueron exitosamente agregadas a su almacén de ventas",
                  allowOutsideClick: false,
                }).then((r) => {
                  if (r.isConfirmed) {
                    this.$close();
                    this.getData();
                  }
                });
              } else {
                this.$close();
                Swal.close();
                Swal.fire({
                  icon: "warning",
                  title: "Error al registrar los datos de stock",
                  html: "<div align='left'>" + response.data.message + "</div>",
                });
              }
            },
            (error) => {
              this.$close();
              Swal.close();
              Swal.fire({
                icon: "error",
                title: "Error",
                text: "Error al registrar los datos de stock" + error,
                footer: '<a href="">Contacte con el administrador</a>',
              });
            }
          );
        } else {
          console.log("cancelar");
        }
      });
    },
    filterByCodigo(item) {
      console.log(item);
      return this.$filterBy(item, "codigo");
    },
    filterByItem(item) {
      return this.$filterBy(item, "item");
    },
    filterByDescripcion(item) {
      return this.$filterBy(item, "descripcion");
    },
    filterByNumero_desde(item) {
      return this.$filterBy(item, "numero_desde");
    },
    filterByNumero_hasta(item) {
      return this.$filterBy(item, "numero_hasta");
    },
    filterByCantidad(item) {
      return this.$filterBy(item, "cantidad");
    },
    filterByNumero(item) {
      return this.$filterBy(item, "numero");
    },
    filterByAlmacen(item) {
      return this.$filterBy(item, "almacen");
    },
    formatDate(timestamp) {
      console.log(timestamp);
      if (timestamp === null) {
        return "";
      }

      const date = new Date(timestamp);
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    },
  },
  mounted() {
    console.log("Componente " + this.baseUrl + " creado");
  },
};
</script>
<style scoped>
.centered-input >>> input {
  text-align: center;
  font-size: 1.2rem;
  color: #105cfa !important;
}
</style>
